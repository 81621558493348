import React from 'react'
import Layout from '../components/layout-v2';


const Page = () => (

  <Layout title="Get Started">
    <section className="section-get-started">



      <section className="row">

        <div className="col-md-1">&nbsp;</div>
        <div className="col-md-4 col-xs-12">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">School Staff or Parent/Student</h5>
              <p className="card-text">


              </p>
            </div>
          </div>
        </div>
        <div className="col-md-2">&nbsp;</div>

        <div className="col-md-4 col-xs-12">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">School Owner or Administrator</h5>
              <p className="card-text">

              </p>
            </div>
          </div>

        </div>
        <div className="col-md-1">&nbsp;</div>

      </section>

    </section>
  </Layout>
);

export default Page;
